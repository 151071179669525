<script setup lang="ts">
import type { mastodon } from '#types'

const { hashtag } = defineProps<{
  hashtag: mastodon.v1.Tag
}>()

const totalTrend = computed(() =>
  hashtag.history?.reduce((total: number, item) => total + (Number(item.accounts) || 0), 0)
)
</script>

<template>
  <div>
    <div>
      <span>
        {{ hashtag.name }}
      </span>
      <!-- <CommonTrending v-if="hashtag.history" :history="hashtag.history" /> -->
    </div>
    <div v-if="totalTrend && hashtag.history">
      <!-- <CommonTrendingCharts :history="hashtag.history" :width="150" :height="20" /> -->
    </div>
  </div>
</template>
